import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Progress from '../component/progress'
import {
  requestUpsell,
  requestUpsellOptions,
  selectPortfolio,
  selectPackageCore,
  selectInstallationUpsell,
  goToStepUpsell,
  // requestSubmitStep1,
  requestContractUpsellStep1,
  // requestSubmitStep2,
  requestContractUpsellStep2,
  // requestSubmitStep3,
  requestContractUpsellStep3,
  setPaymentAction, setPrevStepUpsell,
  // requestNewServicePortfolios,
  // requestNewServiceOptions,
} from '../actions/services'
// import { startLoading, stopLoading } from '../actions/app'

import { getContract } from '../actions/admin'

import { validatePayment } from '../actions/invoices'

import Modal from 'react-modal'
import ModalLogo from '../component/modal-logo'
import { modalStyle } from '../utils/modal-style'

import Loading from '../component/loading'

import AdminBlankContent from '../layout/admin-blank-content'
import DigiModalContext from '../context/digi-modal-context'

import WithLoader from '../hoc/with-loader'

import ServicesActivationStep1 from './services-activation-step-1-u'
import ServicesActivationStep2 from './services-activation-step-2-u'
import ServicesActivationStep3 from './services-activation-step-3-u'
import ServicesActivationStep4 from './services-activation-step-4-u'
import {GO_TO_STEP_UPSELL, PA_NEW_CONTRACT} from '../actions/types'
import TagManager from 'react-gtm-module'
import { isGTMEnabled } from '../utils/functions'
import contractHoc from '../hoc/contract-hoc'
import { ca } from 'date-fns/locale'

// import { formatPhone, findContactBaseMobile } from '../utils/functions'

class Services extends Component {
  static contextType = DigiModalContext

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      selectedCore: props.packageCore,
      selectedPackages: this.preselectPackages(),
    }

    this.baseState = this.state

    this.toggleModal = this.toggleModal.bind(this)
    this.handleServices = this.handleServices.bind(this)
    this.selectPortfolio = this.selectPortfolio.bind(this)
    this.handleSubmitStep1 = this.handleSubmitStep1.bind(this)
    this.handleSubmitStep2 = this.handleSubmitStep2.bind(this)
    this.handleSubmitStep3 = this.handleSubmitStep3.bind(this)
    this.preselectPackages = this.preselectPackages.bind(this)

    props.requestUpsell(true)

    var script = document.createElement('script')
    script.src = 'https://gw.sandbox.gopay.com/gp-gw/js/embed.js'
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  toggleModal(description = '') {
    this.setState({
      modal: !this.state.modal,
      description: description,
    })
  }

  // GA
  gaECDetail(coreID, portfolioID) {
    if (isGTMEnabled()) {
      // TODO: upsell nema portfolia
      let portfolioKey
      try {
        portfolioKey = this.props.portfolios.filter(
          (p) => p.id === portfolioID
        )[0].group
      } catch (error) {
        portfolioKey = 'TV_OTT'
      }

      const product = this.props.options.packages.core[coreID]

      const category =
        portfolioKey === 'TV_OTT'
          ? 'Internetová televize'
          : 'Satelitní televize'
      let dl = {
        dataLayer: {
          event: 'view_item',
          ecommerce: {
            currency: 'CZK',
            purchaseType: 'upsell',
            items: [
              {
                item_name: product.name,
                item_id: coreID,
                price: product.price,
                item_brand: 'Telly',
                item_category: 'Televize',
                item_variant: category,
                quantity: 1,
              },
            ],
          },
        },
      }
      console.log(dl)
      TagManager.dataLayer(dl)
    }
  }

  handleServices(values, kind, form) {
    let newlySelectedCore = this.state.selectedCore
    let newlySelectedPackage = this.state.selectedPackages

    const otherSelectedPackages = Object.entries(this.props.options.packages).reduce(
      (acc, [category, packages]) => {
        if (category !=="core") {
          Object.entries(packages).forEach(([key, packageDetails]) => {
            if (packageDetails.selected ) {
              acc.push(key);
            }
          });
        }
        return acc;
      },
      []
    );
    
    newlySelectedPackage = Array.from(new Set([...newlySelectedPackage, ...otherSelectedPackages]));

    if (newlySelectedCore === null) {
      const corePackages = Object.entries(this.props.options.packages.core);
      const activeCorePackage = corePackages.find(pckg => pckg[1].selected === true);
      if (activeCorePackage) {
        newlySelectedCore = activeCorePackage[0];
      }
    }

    if (kind === 'core') {
      newlySelectedCore = values
      this.props.selectPackageCore(newlySelectedCore)

      // GTM
      this.gaECDetail(newlySelectedCore, this.props.selectedPortfolio)
    } else if(kind === 'multiscreen') {
      if (newlySelectedPackage.includes(values)) {
        newlySelectedPackage.splice(newlySelectedPackage.indexOf(values), 1)
      } else {
        newlySelectedPackage = newlySelectedPackage.filter(
          (item) => !item.includes('MULTISCREEN')
        );
        newlySelectedPackage.push(values);
      }
    } else {
      if (newlySelectedPackage.includes(values))
        newlySelectedPackage.splice(newlySelectedPackage.indexOf(values), 1)
      else newlySelectedPackage.push(values)
    }

    this.setState(
      {
        selectedCore: newlySelectedCore,
        selectedPackages: newlySelectedPackage,
      },
      () => {
        this.props.requestUpsellOptions({
          // portfolio: this.props.selectedPortfolio,
          packages: this.getSelectedPackages()
        }, form)
      }
    )
  }
  
  selectPortfolio(portfolioId) {
    if (this.props.selectedPortfolio === portfolioId) return
    this.props.selectPortfolio(portfolioId)
    // if (this.getSelectedPackages().length > 0) {
    //   this.setState(
    //     {
    //       selectedPackages: [],
    //     },
    //     () => {
    //       this.props.requestNewServiceOptions({
    //         portfolio: portfolioId,
    //         packages: this.getSelectedPackages(),
    //       })
    //     }
    //   )
    // }
    this.props.requestNewServiceOptions({
      portfolio: portfolioId,
      packages: this.getSelectedPackages(),
    })

    // GA
    this.gaECDetail(this.state.selectedCore, portfolioId)
  }

  preselectPackages() {
    const selectedPackages = [];
    Object.entries(this.props?.options?.packages || []).forEach(([key, value]) => {
      if(key === 'ppv' || key === 'other' || key === 'suppl' || key === 'device') {
        Object.entries(value).forEach(([key, value]) => {
          if(value.selected) {
            selectedPackages.push(key);
          }
        });
      }});

    return selectedPackages;
  }

  getSelectedPackages() {
    return this.state.selectedCore !== null
      ? [...this.state.selectedPackages, this.state.selectedCore]
      : this.state.selectedPackages
  }

  handleSubmitStep1() {
    this.props.requestContractUpsellStep1().then(() => {
      this.props.goToStepUpsell(2)
      this.props.setPrevStepUpsell(1)
    })
  }

  handleSubmitStep2() {
    this.props.requestContractUpsellStep2().then(() => {
      this.props.goToStepUpsell(3)
      this.props.setPrevStepUpsell(2)

    })
  }

  async handleSubmitStep3({ password }, payNow) {
    try {
      this.props.startLoading(payNow ? 'Čeká se na platební bránu' : '')
      const res = await this.props.requestContractUpsellStep3({
        password,
      })
      if(res.type === GO_TO_STEP_UPSELL) {
        this.props.goToStepUpsell(3)
        this.props.stopLoading()
        return
      }
      // this.setState({ loading: true })
      if (res.gate_url) {
        this.props.setPaymentAction(PA_NEW_CONTRACT)
        window._gopay.checkout(
          { gatewayUrl: this.props.gateURL, inline: true },
          async function (checkoutResult) {
            try {
              if (checkoutResult && checkoutResult.id) {
                await this.props.validatePayment(this.props.midasID)
                await this.props.getContract()
                this.props.goToStepUpsell(4)
              }
            } catch (err) {
            } finally {
              this.setState({ loading: false })
              this.props.stopLoading()
            }
          }.bind(this)
        )
      } else {
        // TODO: pokud neprijde gate_url (tzn. platba prevodem apod.)
        try {
          await this.props.getContract()
          this.props.goToStepUpsell(4)
        } catch (err) {
          this.props.goToStepUpsell(3)
        }

        // this.setState({ loading: false })
        this.props.stopLoading()
      }
    } catch (err) {
      this.props.stopLoading()
    } finally {
      this.context.toggleDigiModal()
    }
  }

  render() {
    // const contract = this.props.contracts[this.props.contract_id] || {}
    const {
      content,
      help,
      options,
      portfolios,
      selectedPortfolio,
      advertisement, // TODO: smazat
      deviceTotal,
      packageCore,
      contract,
    } = this.props
    // const baseMobileContact = findContactBaseMobile(user.contacts)

    let portfolioKey
    try {
      portfolioKey = contract.portfolio.group;
    } catch (error) {
      portfolioKey = 'TV_OTT'
    }

    return (
      <AdminBlankContent
        advertisementTarget="signup.user.selection.banner"
        title="Moje služby"
        subtitle={content['content.upsell.contract.title']}
        help={help['help.upsell.contract.help']}
        link="/registrace/vyber-sluzby"
      >
        <div className="row services">
          <div className="col col--is--12 col--big">
            <Modal
              appElement={document.getElementById('root')}
              style={modalStyle}
              isOpen={this.state.modal}
            >
              <ModalLogo />
              <button className="button-close" onClick={this.toggleModal}>
                zavřít
              </button>

              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.description,
                }}
              />
            </Modal>
            <div className="admin__inner relative">
              {(this.props.loading || this.state.loading) && <Loading />}
              <div className="row">
                <div className="col  col--md--9 col--xl--7">
                  {this.props.step <= 3 && (
                    <Progress
                      labels={['Krok 1', 'Krok 2', 'Krok 3']}
                      current={this.props.step}
                      goToStep={this.props.goToStepUpsell}
                    />
                  )}
                  {1 === this.props.step && (
                    <ServicesActivationStep1
                      options={options}
                      portfolios={portfolios}
                      selectedPortfolio={selectedPortfolio}
                      selectPortfolio={this.selectPortfolio}
                      toggleModal={this.toggleModal}
                      handleSubmitStep1={this.handleSubmitStep1}
                      handleServices={this.handleServices}
                      handleInstallation={this.props.selectInstallation}
                      selectedInstallation={this.props.selectedInstallation}
                      contract={contract}
                      deviceTotal={deviceTotal}
                      selectedPackageCore={packageCore}
                    />
                  )}
                  {2 === this.props.step && (
                    <ServicesActivationStep2
                      goToStep={this.props.goToStepUpsell}
                      handleSubmit={this.handleSubmitStep2}
                      toggleModal={this.toggleModal}
                      options={options}
                      portfolioKey={portfolioKey}
                    />
                  )}
                  {3 === this.props.step && (
                    <ServicesActivationStep3
                      goToStep={this.props.goToStepUpsell}
                      toggleModal={this.toggleModal}
                      onSubmit={this.handleSubmitStep3}
                      portfolioKey={portfolioKey}
                    />
                  )}
                  {4 === this.props.step && (
                    <ServicesActivationStep4 portfolioKey={portfolioKey} />
                  )}
                </div>
                {/* // TODO: presunout */}
                <div className="col  col--md--3 col--xl--5">
                  {advertisement['signup.user.selection.banner'] &&
                    advertisement['signup.user.selection.banner']['right'] &&
                    advertisement['signup.user.selection.banner']['right']
                      .content && (
                      <div className={`message-wrapper`}>
                        <div
                          className={`message-block message-block--side`}
                          dangerouslySetInnerHTML={{
                            __html:
                              advertisement['signup.user.selection.banner'][
                                'right'
                              ].content,
                          }}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminBlankContent>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    help: state.translation.help,
    content: state.translation.content,
    loading: state.contract_upsell.loading,
    // portfolios: state.contract_new.portfolios,
    options: state.contract_upsell.options,
    // selectedPortfolio: state.contract_upsell.selectedPortfolio,
    selectedInstallation: state.contract_upsell.selectedInstallation,
    step: state.contract_upsell.step,
    packageCore: state.contract_upsell.selectedPackageCore,
    gateURL: state.contract_upsell.overview.gate_url,
    midasID: state.contract_upsell.overview.midas_id || null,
    advertisement: state.advertisement, // TODO: smazat
    deviceTotal: state.contract_upsell.deviceTotal,
  }
}

export default compose(
  connect(mapStateToProps, {
    requestUpsell,
    requestUpsellOptions,
    // requestSubmitStep1,
    requestContractUpsellStep1,
    // requestSubmitStep2,
    requestContractUpsellStep2,
    // requestSubmitStep3,
    requestContractUpsellStep3,
    selectPortfolio,
    selectPackageCore,
    selectInstallation: selectInstallationUpsell,
    validatePayment,
    goToStepUpsell,
    setPrevStepUpsell,
    getContract,
    // startLoading,
    // stopLoading,
    setPaymentAction,
  }),
  WithLoader,
  contractHoc
)(Services)
